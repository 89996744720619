export const corsFetchOptions = {
    // Enable cross-domain requests (CORS)
    mode: 'cors',
    // Send credentials cross-domain
    credentials: 'include'
};

export const acceptJsonHeaders = {
    Accept: 'application/json'
};

export const contentTypeJsonHeaders = {
    'Content-Type': 'application/json; charset=utf-8'
};

export const endpointUrl = (endpointUrl) => {
    if (process.env.API_URL) {
        return `${process.env.API_URL}${endpointUrl}`;
    } else {
        return `/api${endpointUrl}`;
    }
};

export const checkStatus = (response) => {
    if (!response.ok) {
        const error = new Error(`${response.status} ${response.statusText}`);
        error.response = response;

        // server error
        if (response.status === 500) {
            throw error;
        }

        //If we get a 401, redirect to the login page and pass the current URL as a redirect parameter.
        if((response.status === 401) && (typeof window !== 'undefined')) {
            const currentUrl = (window.location.pathname + window.location.search);
            window.location = '/api/login?redirect=' + encodeURIComponent(currentUrl);
        }

        // Try to parse the response body as JSON.
        return response.json().then(
            (body) => {
                const errorMessage = body.error === 'Forbidden' 
                ? 'Forbidden. Your session may have expired. Refresh this page, then log in again.'
                : body.error
                return {
                    success: false,
                    error: errorMessage,
                    code: response.status
                }
            },
            () => {
                // shouldn't hapen
                throw error;
            }
        );
    } else {
        return response.json().then(
            (body) => {
                return {
                    success: true,
                    result: body
                }
            }
        )
    }
};

export const get = (url, cookie) => {        
    const headers = {
        ...acceptJsonHeaders,
    };

    // server side only
    if (cookie) {
        headers['Cookie'] = cookie;
    }

    const fetchOptions = {
        ...corsFetchOptions,
        method: 'GET',
        headers
    };
    const finalUrl = endpointUrl(url)

    return fetch(finalUrl, fetchOptions)
        .then(checkStatus);
}

const requestWithBody = (method, url, data, cookie) => {
    const headers = {
        ...acceptJsonHeaders,
        ...contentTypeJsonHeaders
    };
    // server side only
    if (cookie) {
        headers['Cookie'] = cookie;
    }
    const body = JSON.stringify(data);
    const fetchOptions = {
        ...corsFetchOptions,
        method,
        headers,
        body
    };

    const finalUrl = endpointUrl(url);

    return fetch(finalUrl, fetchOptions)
        .then(checkStatus)
};

export const post = (url, data, cookie) => requestWithBody('POST', url, data, cookie);
export const put = (url, data, cookie) => requestWithBody('PUT', url, data, cookie);
export const patch = (url, data, cookie) => requestWithBody('PATCH', url, data, cookie);
export const destroy = (url, cookie) => {
    const headers = {
        ...acceptJsonHeaders
    };

    // server side only
    if (cookie) {
        headers['Cookie'] = cookie;
    }
    const fetchOptions = {
        ...corsFetchOptions,
        method: 'DELETE',
        headers
    };

    const finalUrl = endpointUrl(url)

    return fetch(finalUrl, fetchOptions)
        .then(checkStatus);
};